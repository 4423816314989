<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-textarea
                    outlined
                    v-model="glnString"
                    label="Importa GLN / Codice Cliente"
                    rows="12"
                    no-resize
                    required
                ></v-textarea>
            </v-col>
            <v-col cols="12" v-if="showAlert">
                <v-alert
                    :type="alertType"
                    >{{ alertMessage }}</v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as storeActions from "../../../store/actions/store";

export default {

    props: ['farmId', 'stores'],

    data: () => ({
        glnString: "",
        alterType: '',
        alterMessage: '',
        showAlert: false
    }),

    watch: {
        glnString: function(newVal) {
            this.parseGLN();
        }
    },

    computed: {
        ...mapState({
            farmStores: state => state.storeModule.stores,
            loaded: state => state.storeModule.loaded
        })
    },

    methods: {
        ...mapActions({
            loadStores: storeActions.STORE_GET_STORES
        }),
        async getStores(farmId) {
            try {
                if (!this.loaded) {
                    const state = await this.loadStores(farmId);
                }
                return this.farmStores;
            } catch (err) {
                console.error(err);
            }
        },

        async parseGLN() {
            this.showAlert = false;
            if (this.glnString.length === 0) {
                this.$emit('update:stores', [])
                return;
            }
            const farmStores = await this.getStores(this.farmId);

            // console.log(this.glnString.toString().replace(/(\r\n|\n|\r)/gm, ","), farmStores)
            // considero l'eventuale presenza di duplicati
            const glns = [...new Set(this.glnString.toString().replace(/(\r\n|\n|\r)/gm, ",").split(','))];
            const stores = farmStores.filter(store => {
                return glns.includes(store.CodiceNazionaleCoopGLN) && store.CodiceNazionaleCoopGLN.length !== 0 || glns.includes( parseInt(store.CodiceClienteEsterno.substr(store.CodiceClienteEsterno.length-4)).toString() ) && store.CodiceNazionaleCoopGLN.length > 0
            })
            const storesGLN = stores.map(x => x.CodiceNazionaleCoopGLN);
            const storesCode= stores.map(x => parseInt(x.CodiceClienteEsterno.substr(x.CodiceClienteEsterno.length-4)).toString())
            const idClienti = stores.map(x => x.IDCliente)
            const notFound = glns.filter(g => {
                return !storesGLN.includes(g) && !storesCode.includes(g);
            });
            if (notFound.length === 0) {
                this.alertType = 'success'
                this.alertMessage = `Importati ${stores.length} su ${glns.length} negozi!`
            }
            else {
                this.alertType = 'warning'
                this.alertMessage = `Importati ${stores.length} su ${glns.length} negozi, non trovati: ${notFound.join(' ')}`
            }
            this.showAlert = true
            // console.log(stores.length, notFound);
            this.$emit('update:stores', idClienti)
        }
    },

    beforeMount() {
    },

    mounted() {
    }
};
</script>

<style></style>
